import { autoinject } from 'aurelia-framework';
import { ZaiForm } from 'zailab.common';

interface ITab1ConnectingConfig {
  enabled: boolean;
  userSyncInterval: string | number;
  clientId: string;
  tenantId: string;
  clientSecret: string;
  valid?: boolean;
}
@autoinject
export class Tab1ConnectingConfig {
  public form: any[][];
  private model: { data: ITab1ConnectingConfig };

  public data: ITab1ConnectingConfig = {
    enabled: false,
    userSyncInterval: '',
    clientId: '',
    tenantId: '',
    clientSecret: '',
    valid: false
};

public validate(): boolean {
    this.data.valid = !!this.data.clientId && !!this.data.tenantId && !!this.data.clientSecret;
    return this.data.valid;
}

public getData(): ITab1ConnectingConfig {
    this.validate();
    return this.data;
}

  public activate(bindingContext: { data: ITab1ConnectingConfig }) {
    this.model = bindingContext;

    this.generateForm();
  }

  private generateForm(): void {
    new ZaiForm()
      .newField()
      .asSliderToggle()
      .withIdentifier('enabled')
      .withTitle('Status', '200px')
      .addNegativeOption('Disabled')
      .addPositiveOption('Enabled')
      .withValue(this.model.data.enabled) 
      .insertField()

      .newRow()
      .newField()
      .asDropdown()
      .withIdentifier('userSyncInterval')
      .withTitle('User Sync Interval', '200px')
      .withOptions(['15', '30', '120', '180'])
      .withValue(this.model.data.userSyncInterval)
      .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
      .insertField()

      .newRow()
      .newField()
      .asTextInput()
      .withIdentifier('clientId')
      .withTitle('Client ID', '200px')
      .withValue(this.model.data.clientId)
      .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
      .insertField()

      .newRow()
      .newField()
      .asTextInput()
      .withIdentifier('tenantId')
      .withTitle('Tenant ID', '200px')
      .withValue(this.model.data.tenantId)
      .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
      .insertField()

      .newRow()
      .newField()
      .asTextInput()
      .enableSecure()
      .withIdentifier('clientSecret')
      .withTitle('Client Secret', '200px')
      .withValue(this.model.data.clientSecret)
      .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.REQUIRED }])
      .insertField()

      .finaliseForm((form) => {
        this.form = form;
      });
  }

  public formDataChanged(data: any): void {
    this.model.data = { ...data };
  }
}