import { autoinject, LogManager } from 'aurelia-framework';
import { ArrayTools, FormatTools, SessionStore } from "zailab.common";
import { PresenceService } from '../../../../user/passport/presence/presence-service';

const logger = LogManager.getLogger('Tab2PresenceMapping');

interface ITab2PresenceMapping {
  presenceMappings: { [key: string]: any };
}

interface Mapping {
  msTeams: string;
  zailab: string;
}

@autoinject
export class Tab2PresenceMapping {

  public tableColumns = ['MS Teams Presences', 'Zailab Presences'];
  public tableTypes;
  public tableTypesDefault = [
    {
      type: 'text',
      validation: 'required',
      uniqueKey: true,
    },
    {
      type: 'dropdown',
      options: [],
      direction: 'bottom',
      validation: 'required',
      uniqueKey: false,
      displayField: 'label'
    },
  ];
  public tableData = [];

  public newPresence: Mapping = { msTeams: '', zailab: '' };
  public presenceMappings: Mapping[] = [];
  public zailabPresences: string[] = [];
  private model: { data: ITab2PresenceMapping };

  constructor(
    private presenceService: PresenceService,
    private sessionStore: SessionStore
  ) {
    this.retrievePresences();
  }

  public activate(bindingContext: { data: ITab2PresenceMapping }) {
    this.model = bindingContext;
    this.tableData = Object.keys(this.model.data.presenceMappings).map(key => ([
      key,
      this.model.data.presenceMappings[key]
    ]));
  }

  private async retrievePresences(): Promise<void> {
    try {
      let presences: { presenceCodeName: string; color: string; }[] = await this.presenceService.retrievePresences();
      let offlinePresence = presences.find((presence) => 'OFFLINE' === presence.presenceCodeName);
      if (!offlinePresence) {
        presences.push({ presenceCodeName: 'OFFLINE', color: 'gray' });
      }
      presences = ArrayTools.sort(presences, 'presenceCodeName');
      const formatedPresences = presences.map(presence => {
        return {
          label: presence.presenceCodeName
        };
      });
      this.tableTypesDefault[1].options = formatedPresences;
      this.tableTypes = this.tableTypesDefault;
    } catch (error) {
      logger.error('Could not retrieve presence code:', error);
    }
  }

  public tableDataUpdated(data: any): void {
    this.model.data.presenceMappings = {};
    data.forEach((item) => {
      this.model.data.presenceMappings[item[0]] = item[1];
    });
  }
}

